// TODO: Add to this documentation
//
// data-autosubmit: Causes a change in the select box to autosubmit it's form

import $ from "jquery"
import selectize from '@selectize/selectize/dist/js/selectize'
import Loader from "@/javascript/components/loader"
import { SelectizeToggler } from "@/javascript/components/toggling"
import reportError from "@/javascript/components/errors"

const originalSelectize = $.fn.selectize

// var getParents
const MAX_DROPDOWN_HEIGHT = 400
const DROPDOWN_DIRECTION_BREAKPOINT = 250

const getParents = (elem) => {
  const result = []

  while ((elem = elem.parentNode) !== document.body) {
    result.push(elem)
  }
  return result
}

export default function Selectize (element, givenOptions) {
  return new Promise((resolve, _reject) => {
    const options = {}
    options.dropdownParent = 'body'
    if(!element.classList.contains('with-search')) {
      options.search = false
    }
    if(!options.hasOwnProperty('createOnBlur')) {
      options.createOnBlur = true
    }
    if(element.classList.contains('allow-add')) {
      options.create = true
    }
    options.sortField = element.dataset.sortField

    if(givenOptions && givenOptions.render) {
      options.render = givenOptions.render
      delete givenOptions.render
    } else {
      options.render = {}
    }

    if (element.dataset.autocomplete && element.dataset.autocompleteUrl) {
      options.load = function(query, callback) {
        if(!query.length){
          return
        }

        const url = new URL(element.dataset.autocompleteUrl, window.location.origin)
        url.searchParams.set('q', query)

        return fetch(url)
          .then((response) => response.json())
          .then((data) => callback(data) )
          .catch((error) => {
            reportError(error)
            callback()
          })
      }

      options.onLoad = function () {
        this.loadedSearches = {}
        this.renderCache = {}
        this.$wrapper.removeClass(this.settings.loadingClass)
      }
    }

    options.render['item'] ||= function (data, escape) {
      let result = '<div class="item">' + escape(data[this.settings.labelField])
      if(this.settings.mode === 'multi') {
        result += '<span class="remove"></span>'
      }
      result += '</div>'
      return result
    }

    Object.entries(givenOptions).forEach((entry) => {
      const [key, value] = entry
      options[key] = value
    })

    options.onChange = function (event) {
      this.$input[0].dispatchEvent(new Event('change', {bubbles: true}))
    }

    options.onInitialize = function () {
      this.on('dropdown_open', () => {
        if(this.scrollingSorted) {
          return
        }

        getParents(this.$wrapper[0]).forEach((element) => {
          element.addEventListener('scroll', () => {
            const wrapperBounds = this.$wrapper[0].getBoundingClientRect()
            const elementBounds = element.getBoundingClientRect()
            if(wrapperBounds.bottom < elementBounds.top ||
              wrapperBounds.top > elementBounds.bottom) {
              this.close()

            } else {
              this.positionDropdown()
            }
          })
        })

        this.scrollingSorted = true
      })

      new OptionToggler(element).bind()
      resolve(this)
    }

    originalSelectize.apply($(element), [options])

    if(element.classList.contains('nokeyboard')) {
      element.selectize.$control_input[0].readOnly = true
    }

    element.selectize.$control_input[0].autocomplete = 'new-password'

    if(element.dataset.autosubmit) {
      element.selectize.on('change', (_newValue) => {
        if(element.form) {
          element.form.requestSubmit()
        }
      })
    }
  })
}

$.fn.selectize = (options) => {
  this.forEach((elem) => {
    Selectize(elem, options)
  })
  return this
}

Object.entries(originalSelectize).forEach((entry) => {
  const [prop, value] = entry

  if(originalSelectize.hasOwnProperty(prop)) {
    $.fn.selectize[prop] = value
  }
})

$(document).on('click', '.selectize-control.multi .item .remove', function () {
  const $this = $(this)
  const selectize = $this.closest('.selectize-control').siblings('select')[0].selectize
  if(selectize.isDisabled) {
    return
  }

  selectize.removeItem($this.closest('.item').attr('data-value'))
  selectize.showInput()
  selectize.positionDropdown()
  selectize.refreshOptions(true)
})

Loader.functional((container, resolve) => {
  Promise.all(
    [...container.querySelectorAll('select')]
      .filter((element) => !element.classList.contains('no-js'))
      .map((element) => Selectize(element, {search: true}))
  ).then(resolve)
})

selectize.prototype.updateDropDownPosition = function () {
  const dropdown = this.$dropdown[0]
  const rect = dropdown.getBoundingClientRect()
  const maxHeight = window.innerHeight - rect.top

  if(maxHeight < MAX_DROPDOWN_HEIGHT && maxHeight > DROPDOWN_DIRECTION_BREAKPOINT) {
    this.topPositioned = false
    this.$dropdown_content[0].style.setProperty('max-height', `${maxHeight}px`)
    return
  }

  this.topPositioned = dropdown.getBoundingClientRect().bottom > window.innerHeight
}

const originalPostionDropdown = selectize.prototype.positionDropdown
selectize.prototype.positionDropdown = function () {
  originalPostionDropdown.apply(this, [])
  const dropdown = this.$dropdown[0]
  const originControl = this.$control[0]
  const originControlWidth = originControl.offsetWidth
  this.$dropdown_content[0].style.removeProperty('max-height')
  const extraWidth = getComputedStyle(dropdown).getPropertyValue('--extra-width')

  this.updateDropDownPosition()

  if(this.topPositioned) {
    const newTop = parseInt(dropdown.style.top) - this.$dropdown.height() - this.$control.outerHeight(true) - 4;
    dropdown.style.setProperty('top', newTop + 'px')
  }

  if(extraWidth && originControlWidth) {
    dropdown.style.setProperty('width', 'calc(' + originControlWidth + 'px + ' + extraWidth + ')')
  } else {
    dropdown.style.setProperty('width', originControlWidth + 'px')
  }
}

const originalRefreshOptions = selectize.prototype.refreshOptions
selectize.prototype.refreshOptions = function () {
  const result = originalRefreshOptions.apply(this, arguments)
  this.positionDropdown()
  return result
}

// Bind a toggler for select option that have `data-toggle='<css-selectors>'`
//
// This can be used to have a select option that toggles multiple elements when selected
class OptionToggler {
  constructor (select) {
    this.select = select
  }

  bind () {
    const elemsToValues = new ElementsToValues
    const children = this.select.selectize.revertSettings.$children
    const options = [...children.find('option').add(children.filter('option'))] // Find options in optgroups too

    options
      .filter((option) => option.dataset.toggle)
      .forEach((option) => {
        elemsToValues.add(option.dataset.toggle, option.value)
      })

    Object.entries(elemsToValues.mapping).forEach((entry) => {
      const [elem, values] = entry
      const toggledContent = document.querySelector(elem)
      new SelectizeToggler(this.select, toggledContent, null, {values: values})
    })
  }
}

// Used to flatten the mapping of CSS selectors to toggle to selected options.
//
// This is because the elements to be toggled can be on multiple select options
// in different combinations and we only want one toggler per toggled element,
// or they will fight each other
class ElementsToValues {
  constructor () {
    this.mapping = {}
  }

  add (selector, value) {
    selector.split(',').forEach((newSelector) => {
      newSelector = newSelector.trim()
      this.mapping[newSelector] ||= []
      this.mapping[newSelector].push(value)
    })
  }
}

// Non-selectize logic
// This is the logic for selects that are not selectize

document.addEventListener('change', (e) => {
  const target = e.target
  if(!target.matches('select.no-js')) { return }

  if(target.dataset.autosubmit && target.form) {
    target.form.requestSubmit()
  }
})
